/* 全局样式 */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4; /* 背景颜色 */
  color: #333; /* 全局字体颜色 */
}

/* 导航栏样式 */
.navbar {
  background-color: #333; /* 导航栏背景色 */
  padding: 1em; /* 导航栏内边距 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 添加阴影 */
}

.nav-list {
  list-style: none; /* 去掉列表样式 */
  display: flex;
  justify-content: center; /* 居中对齐 */
  margin: 0;
  padding: 0;
}

.nav-item {
  margin: 0 1em; /* 导航项之间的间距 */
}

.nav-link {
  color: white; /* 链接文字颜色 */
  text-decoration: none; /* 去掉下划线 */
  font-size: 1.1em; /* 字体大小 */
  transition: color 0.3s ease; /* 添加颜色渐变效果 */
}

.nav-link:hover {
  color: #e4eaeb; /* 鼠标悬停时的颜色 */
}

.nav-link:focus {
  outline: 2px solid #ff9800;
}


/* 当前页面高亮 */
.nav-link.active {
  color: #ff9800;
  font-weight: bold;
}

/* 全局内容样式 */
main {
  padding: 2em;
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 媒体查询 - 响应式设计 */
@media (max-width: 768px) {
  .nav-list {
    flex-direction: column; /* 垂直排列导航项 */
    align-items: center; /* 居中对齐 */
  }

  .nav-item {
    margin: 0.5em 0; /* 调整导航项之间的间距 */
  }
}