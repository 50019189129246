.students-container {
    text-align: center;
    padding: 50px 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .students-container h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .students-container .student-card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 10px;
    padding: 20px;
    width: 80%;
    margin: 0 auto 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .students-container .student-card:hover {
    transform: scale(1.05);
  }
  
  .students-container .student-card h2 {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .students-container .student-card p {
    font-size: 1em; /* 设置合适的字体大小 */
    color: #555; /* 调整颜色以提高可读性 */
    line-height: 1.6; /* 增加行高，使文本更易于阅读 */
    margin: 0 0 10px; /* 添加底部外边距，与上下方内容分离 */
  }
    
    