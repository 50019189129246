.contact-container {
    text-align: center;
    padding: 50px 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-container h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .contact-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-container input, .contact-container textarea {
    width: 80%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .contact-container button {
    background: #333;
    color: white;
    border: none;
    padding: 15px 20px;
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .contact-container button:hover {
    background: #555;
  }
  
  @media (max-width: 768px) {
    .contact-container {
      padding: 30px 15px;
    }
  
    .contact-container h1 {
      font-size: 2.2em;
    }
  }
  