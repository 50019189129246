/* 针对整体背景的样式 */
body {
  background-color: #f5f5f5; /* 提供浅灰背景色 */
  margin: 0;
  font-family: 'Roboto', sans-serif; /* 与 Material-UI 组件保持一致 */
}

/* 容器样式 */
.about-container {
  padding: 30px 20px; /* 页面内边距 */
  max-width: 1200px; /* 更大宽度适应多栏布局 */
  margin: 20px auto; /* 居中 */
  background-color: #ffffff; /* 白色背景 */
  border-radius: 10px; /* 圆角效果 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影 */
}

/* 卡片样式 */
.card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card img {
  border-radius: 10px;
  object-fit: cover;
}

/* 标题和文本样式 */
.about-container h1, .about-container h2 {
  color: #333; /* 统一深灰色标题 */
}

.about-container h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.about-container h2 {
  font-size: 2em;
  margin-top: 20px;
}

.about-container p {
  font-size: 1.2em;
  color: #555;
  line-height: 1.6em;
  margin: 10px 0;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .about-container {
    padding: 20px 15px;
  }

  .about-container h1 {
    font-size: 2em;
  }

  .about-container p {
    font-size: 1em;
  }

  .card {
    margin: 10px 0;
  }
}



