/* Teachers.css */

/* 定义教师页面的主要容器样式 */
.teachers-container {
    padding: 20px;
    max-width: 1000px;
    margin: 20px auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* 定义教师列表项的样式 */
  .teachers-container .teacher-item {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
  }
  
  /* 为教师列表项中的图片设置样式 */
  .teachers-container .teacher-item img {
    float: left;
    margin-right: 15px;
    border-radius: 50%; /* 圆形图片 */
  }
  
  /* 教师名称和职称样式 */
  .teachers-container .teacher-item h2 {
    margin: 0;
    font-size: 1.5em;
    color: #333;
  }
  
  /* 教师描述的样式 */
  .teachers-container .teacher-item p {
    margin: 5px 0 0;
    color: #666;
  }