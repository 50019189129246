.courses-container {
    text-align: center;
    padding: 50px 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .courses-container h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .courses-container .course-card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 10px;
    padding: 20px;
    width: 80%;
    margin: 0 auto 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .courses-container .course-card h2 {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .courses-container .course-card p {
    font-size: 1.1em;
    color: #555;
    line-height: 1.6em;
  }
  
  @media (max-width: 768px) {
    .courses-container {
      padding: 30px 15px;
    }
  
    .courses-container h1 {
      font-size: 2.2em;
    }
  
    .courses-container .course-card h2 {
      font-size: 1.6em;
    }
  }
  
