/* 主页面容器 */
.home-container {
  text-align: center;
  padding: 50px;
  background-color: #ef3ab0; /* 保持原有背景颜色 */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(240, 17, 17, 0.1);
  max-width: 1200px; /* 保持原有最大宽度 */
  margin: 0 auto;
}

/* 标题样式调整，保留原有设置 */
.home-container h1 {
  font-size: 2.5em;
  color: #400ad4;
  margin-bottom: 20px;
  font-weight: bold;
}

/* 考虑到Material-UI的Card已经具有默认内边距和背景颜色，
   这里我们可以做一些细微调整以保持整体一致性 */
.home-container .MuiCard-root {
  background-color: #fff; /* 强制覆盖为白色背景 */
  margin: 10px; /* 增加卡片间距 */
  border-radius: 12px; /* 增加圆角大小 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
}

.home-container .MuiCardContent-root {
  padding: 20px; /* 调整内边距 */
}

.home-container .MuiTypography-h6 { /* 调整卡片内标题字体大小 */
  font-size: 1.5em;
  color: #333;
}

.home-container .MuiTypography-body1 { /* 调整卡片内文字大小 */
  font-size: 1em;
  color: #666;
}

/* Material-UI Chip组件默认较小，我们可以适当调整其大小和颜色 */
.home-container .MuiChip-root {
  font-size: 0.9em;
  background-color: #b3e5fc; /* 调整为更浅的背景色 */
  color: #0277bd; /* 文字颜色调整 */
  margin: 5px; /* 调整间距 */
}

/* 响应式设计调整 */
@media (max-width: 768px) {
  .home-container {
    padding: 30px;
  }
  .home-container h1 {
    font-size: 2em;
  }
  .home-container .MuiTypography-h6 {
    font-size: 1.2em;
  }
  .home-container .MuiTypography-body1 {
    font-size: 0.9em;
  }
}

  